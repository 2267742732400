import React from 'react';
import './BasicCardSection.scss';

import DefaultImage from '/assets/images/BusinessLandingPage/phones.png'

const BasicCardSection = ({
  title = '',
  header = '',
  content = '',
  picture = '',
  className = '',
}) => {

  return (
    <div className={`basic-card-section ${className}`}>
      <div className={'text-content'}>
        <div className={'title'}>{title}</div>
        <div className={'header'}>{header}</div>
        <div className={'content'}>{content}</div>
      </div>
      <div className={'picture-content'}>
        {picture}
      </div>
    </div>
  )

}

export default BasicCardSection;
