import React from 'react';
import WhiteLogo from '~/assets/images/pinch-logo-white.svg';
import OrangeLogo from '~/assets/images/pinch-logo-orange.svg';
import GreenLogo from '~/assets/images/pinch-logo-green.svg';
import VioletLogo from '~/assets/images/pinch-logo-violet.svg';
import NavyLogo from '~/assets/images/pinch-logo-navy.svg';
import BlueLogo from '~/assets/images/pinch-logo-blue.svg';

import './PinchLogo.scss';

const PinchLogo = ({
  fillColour = 'orange',
  className = '',
  ...otherProperties
}) => {

  function getLogo() {
    switch (fillColour) {
      case 'orange':
        return OrangeLogo;
      case 'green':
        return GreenLogo;
      case 'violet':
        return VioletLogo;
      case 'blue':
        return BlueLogo;
      case 'navy':
        return NavyLogo;
      default:
        return WhiteLogo;
    }
  }

  return (
    <img src={getLogo()} className={`pinch-logo ${className}`}/>
  )
}

export default PinchLogo;
