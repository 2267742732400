import React from 'react';
import Button from '~/components/Button';
import './CallToActionFooter.scss';
import OrangeWave from "~/assets/images/wave-orange-bottom.svg";
import GreenWave from "~/assets/images/wave-green-bottom.svg";
import PurpleWave from "~/assets/images/wave-purple-bottom.svg";
import BlueWave from "~/assets/images/wave-blue-bottom.svg";
import NavyWave from "~/assets/images/wave-navy-bottom.svg";

const CallToActionFooter = ({
  color = '',
  isButton = true
}) => {

  const colorWaveMap = {
    orange: OrangeWave,
    green: GreenWave,
    purple: PurpleWave,
    blue: BlueWave,
    navy: NavyWave
  }

  return (
    <div className={`call-to-action-footer-component ${color}`}>
      {
        isButton
        && <Button colour={`${color}`} to="/#" onClick={() => window.location.href = `mailto:info@pinchfinancial.com`}> Request a Demo </Button>
      }
      <img src={colorWaveMap[color] || OrangeWave}/>
    </div>
  )

}

export default CallToActionFooter;
