import React from 'react';
import './CallToActionHeader.scss';
import DefaultImage from '/assets/images/BusinessLandingPage/phones.png'
import Button from '~/components/Button';
import Wave from '/assets/images/wave.svg';
import WaveOrange from '/assets/images/wave-orange.svg';
import WaveGreen from '/assets/images/wave-green.svg';
import WaveViolet from '/assets/images/wave-violet.svg';
import WaveBlue from '/assets/images/wave-blue.svg';
import WaveNavy from '/assets/images/wave-navy.svg';

const CallToActionHeader = ({
  title = '',
  header = '',
  description = '',
  picture = DefaultImage,
  button = <></>,
  color = 'orange',
  content = ''
}) => {

  function getWave() {
    switch (color) {
      case 'orange':
        return WaveOrange;
      case 'green':
        return WaveGreen;
      case 'violet':
        return WaveViolet;
      case 'blue':
        return WaveBlue;
      case 'navy':
        return WaveNavy;
      default:
        return Wave;
    }
  }
  return (
    <div className={"call-to-action-header-component-reforged"}>
      <div className={`background ${color}`}></div>
      <div className={"header-section"}>
        <div className={"title"}>{title}</div>
        <div className={"header"}>{header}</div>
        <div className={"description"}>{description}</div>
      </div>
      {picture}
      <img src={getWave()} className={"wave"} />
      {button}
      {content &&
      <div className={'content-body'}>
        {content}
      </div>
      }
    </div>
  )
}

export default CallToActionHeader;
