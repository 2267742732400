import React from 'react';
import './Button.scss';

const Button = ({
  className = '',
  children = <></>,
  colour = 'orange',
  ...otherProperties
}) => {
  return (
    <button className={`button ${className} ${colour}`} {...otherProperties}>
      {children}
    </button>
  )
}

export default Button;
