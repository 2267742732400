import React from 'react';
import './NotFoundPage.scss';
import NavigationBar from "~/components/NavigationBar";
import Footer from "~/components/Footer";
import Keys from '~/assets/images/keys.png';

const NotFoundPage = ({

}) => {

  return (
    <div className={'not-found-page-component'}>
      <NavigationBar fillsTo={'orange'} fillThreshold={0}/>
      <div className={'content'}>
        <div className={'text-content'}>
          <div className={'header'}>
            We forget our keys too.
          </div>
          <div className={'description'}>
            The page you're looking for cannot be found.<br/>
            Try a link below?
          </div>
        </div>
        <img src={Keys}/>
      </div>
      <Footer/>
    </div>
  )

};

export default NotFoundPage;