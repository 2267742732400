import React from 'react';
import { Helmet } from 'react-helmet';

import './BrokerPage.scss';

// Components
import NavigationBar from '~/components/NavigationBar';
import CallToActionHeader from "~/components/CallToActionHeader";
import BasicCardSection from "~/components/BasicCardSection";
import CallToActionFooter from "~/components/CallToActionFooter";
import ThreeColumnSection from '~/components/ThreeColumnSection';
import Button from '~/components/Button';
import Footer from '~/components/Footer';

// Images
import CTAMobile from '~/assets/images/desktop-340w.svg';
import CTATablet from '~/assets/images/desktop-576w.svg';
import CTADesktop from '~/assets/images/desktop_alpha_nov2020.png';
import Icon1 from "/assets/images/icons/1-04.svg";
import Icon2 from "/assets/images/icons/1-05.svg";
import Icon3 from "/assets/images/icons/1-06.svg";
import Icon4 from "/assets/images/icons/1-07.svg";
import Icon5 from "/assets/images/icons/1-12.svg";
import Icon6 from "/assets/images/icons/1-13.svg";
import AppMapDesktop from "/assets/images/broker-info.svg";
import AppMapMobile from "~/assets/images/broker-info-mobile.svg";
import AppMapTablet from "~/assets/images/broker-info-tablet.svg";
import PathDesktop from '~/assets/images/path-all-desktop.svg';
import MobilePath from "~/assets/images/process-mobile.svg";
import TabletPath from "~/assets/images/process-tablet.svg";
import AdditionalProducts from "/assets/images/additional-products.svg";
import MobileInsuranceProducts from "~/assets/images/aviva-mobile.svg";
import LendersDesktop from "/assets/images/lenders.svg";
import LendersMobile from "/assets/images/lenders-square.svg";

const containers = [
  {
    "icon": Icon1,
    "header": "Qualified Borrowers",
    "content": "Pinch verifies all data points from identity, income, assets and liabilities through to proof of down payment and more. Key data comes straight from applicant banks and credit reporting agencies saving your agents time and hassle."
  },
  {
    "icon": Icon2,
    "header": "One System for All Parties",
    "content": "Brokers no longer need to wait for real estate agents and applicants to gather documents. At any time, documents can be uploaded and assigned to real estate agents or applicants making easy work of lender offer conditions."
  },
  {
    "icon": Icon3,
    "header": "Automated Submission",
    "content": "There is no need to compromise on lender choice with Pinch. Whether through our own direct lender connections, or through multiple existing broker submission engines, Pinch can submit to a variety of lenders without mortgage brokers doing more work."
  },
  {
    "icon": Icon4,
    "header": "Instant Status Updates",
    "content": "From the time a client registers on the platform, to the time an application is funded, brokerage teams can see the entire application at a glance. If a client reaches out for help, the full record of credit, banking and other details is available."
  },
  {
    "icon": Icon5,
    "header": "Streamlined Compliance",
    "content": "All document collection handled by Pinch is time stamped, including disclosures, consents, and more. Make reporting easy by making the switch to Pinch today."
  },
  {
    "icon": Icon6,
    "header": "Renewals and Switches",
    "content": "Pinch’s platform is not just for new mortgages and pre-approvals. When it comes time to renew, applicants can engage with Pinch again, offering new origination opportunities to mortgages brokerages."
  },
]
const BrokerPage = () => {

  return (
    <>
      <div className='broker-page-component'>
        <NavigationBar fillsTo='violet' />
        <CallToActionHeader
          title={'Mortgage Brokers'}
          header={"Help your clients through every step of their application"}
          button={<Button colour={`violet`} to="/#" onClick={() => window.location.href = `mailto:info@pinchfinancial.com`}> Request a Demo </Button>}
          color={"violet"}
          picture={
            <img srcset={`${CTAMobile} 340w 
                          ${CTATablet} 576w
                          ${CTADesktop} 1218w`}
              sizes={`(max-width: 768px) 340px,
                      (max-width: 1336px) 576px,
                      1218px`}
              src={CTADesktop}
              alt={"Pinch application on a desktop"}
              className={"picture"}
            />
          } content={
            <div className={'content-section'}>
              <div className={'header'}>{"What Makes us Different?"}</div>
              <div className={'content'}>
                {"We are not trying to provide you another deal management platform. Instead, we provide you with a new way to approach digital acquisition. With Pinch, you can stop wasting your time qualifying online lead gen or cold referrals."}
                <br />
                <br />
                {"With our automated platform your agents will be able to focus on applications with completed and verified information so they can focus on advice and fulfilment."}
                <br />
              </div>
            </div>
          }
        />
        <ThreeColumnSection
          title={'Why Pinch'}
          header={'Qualified Digital Mortgage Referrals'}
          containers={containers}
        />
        <div className={"brokers-section"}>
          <BasicCardSection
            title={"Designed for Brokers"}
            header={"Keep Track From Start to Finish"}
            content={"Track, Manage, Collect, and Submit all of your applications and referrals with one platform."}
            picture={
              <picture>
                <source media="(max-width: 1336px)" srcset={`${AppMapTablet}`} />
                <source media="(max-width: 768px)" srcset={`${AppMapMobile}`} />
                <img srcset={`${AppMapMobile} 333w
                            ${AppMapTablet} 859w 
                            ${AppMapDesktop} 1672w`}
                  sizes={`(max-width: 768px) 333px,
                          (max-width: 1336px) 859px,
                          1672px`}
                  src={AppMapDesktop}
                  alt={"Choice of lender options"}
                  className={"picture"}
                />
              </picture>
            }
          />
          <CallToActionFooter color={'purple'} />
        </div>

        <div className={"additional-products-section"}>
          <BasicCardSection
            title={"Additional Products"}
            header={"Protect Your Clients with Automated Insurance"}
            content={"Pinch has direct integrations with Canada’s largest creditor and P&C insurers to help streamline your insurance conversations and conversions."}
            picture={
              <picture>
                <source media="(max-width: 768px)" srcset={`${MobileInsuranceProducts}`} />
                <img srcset={`${MobileInsuranceProducts} 340w
                          ${AdditionalProducts} 1171w`}
                  sizes={`(max-width: 768px) 340px,
                        1171px`}
                  src={AdditionalProducts}
                  alt={"Canada’s largest creditor and P&C insurers"}
                  className={"picture"}
                />
              </picture>
            }
          />
        </div>
        <div className={"lenders-section"}>
          <BasicCardSection
            header={"New Channels, Same Great Lenders"}
            content={"We may be changing the way real estate agents and brokers originate mortgages online, but we’re still working with the lenders you know and trust. Inquire to learn which lenders we work with today."}
            picture={
              <picture>
                <source media="(max-width: 1336px)" srcset={`${LendersMobile}`} />
                <img srcset={`${LendersMobile} 773w 
                          ${LendersDesktop} 1543w`}
                  sizes={`(max-width: 1336px) 773px,
                          1543px`}
                  src={LendersDesktop}
                  alt={"Choice of lender options"}
                  className={"picture"}
                />
              </picture>
            }
          />
        </div>
        <div className={'submission-path-section'}>
          <div className={'content-section'}>
            <div className={'title'}>{"Our Process"}</div>
            <div className={'header'}>{"Origination through to Submission"}</div>
          </div>
          <div className={'picture-section'}>
            <picture>
              <source media="(max-width: 768px)" srcset={`${MobilePath}`} />
              <source media="(max-width: 1336px)" srcset={`${TabletPath}`} />
              <img srcset={`${MobilePath} 329w 
                          ${TabletPath} 810w
                          ${PathDesktop} 1486w`}
                src={PathDesktop}
                alt={"Submission path process"}
                className={"picture"}
              />
            </picture>
          </div>
        </div>
        <Footer />
      </div>
      <Helmet>
        <title>About Pinch Financial</title>
        <meta
          name="description"
          content="Our mission is to make the journey to homeownership simple, fast, and secure."
        />
      </Helmet>
    </>
  )

}

export default BrokerPage;
