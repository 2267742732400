import React from 'react';
import { Link } from 'react-router-dom';
import './LinkButton.css';
import Button from '~/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

const LinkButton = ({
  className = '',
  children = <></>,
  to = '/home',
  onClick = () => {},
  ...otherProperties
}) => {
  return (
    <Link to={to} onClick={onClick}>
      <Button className={`link-button ${className}`} {...otherProperties}>
        {children} <FontAwesomeIcon className='nav-arrow' icon={faLongArrowAltRight}/>
      </Button>
    </Link>
  )
}

export default LinkButton;
