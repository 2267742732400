import React from "react";
import { Helmet } from "react-helmet";

import "./Homepage.scss";
import "~/components/TextSection";

// Components
import NavigationBar from "~/components/NavigationBar";
import Footer from "~/components/Footer";
import CallToActionHeader from "~/components/CallToActionHeader";
import BasicCardSection from "~/components/BasicCardSection";
import CallToActionFooter from "~/components/CallToActionFooter";
import Button from "~/components/Button";

// Images
import LaptopMobile from "~/assets/images/laptop-308w.svg";
import LaptopTablet from "~/assets/images/laptop-512w.svg";
import LaptopDesktop from "~/assets/images/welcome_laptop_alpha_02.png";

import LendersDesktop from "~/assets/images/homepage-lending-options.svg";
import LendersTablet from "~/assets/images/TABLET_Lending-Options.svg";
import LendersMobile from "~/assets/images/MOBILE_Lending-Options.svg";

import WhiteLabel1 from "~/assets/images/white-label1.svg";
import WhiteLabel2 from "~/assets/images/white-label2.svg";
import WhiteLabel3 from "~/assets/images/white-label3.svg";
import WhiteLabel4 from "~/assets/images/white-label4.svg";

import RealEstateDesktop from "~/assets/images/homepage-realEstate.svg";
import RealEstateTablet from "~/assets/images/TABLET_HOMEPAGE_RealEstatePreview.svg";
import RealEstateMobile from "~/assets/images/MOBILE_HOMEPAGE_RealEstatePreview.svg";

import Logos from "/assets/images/logos.png";
import BrokerDesktop from "~/assets/images/homepage-brokerListView.svg";
import BrokerTablet from "~/assets/images/TABLET_Homepage_BrokerListView.svg";
import BrokerMobile from "~/assets/images/MOBILE_Homepage_BrokerListView.svg";

const Homepage = () => {
  return (
    <>
      <div className="homepage-component">
        <NavigationBar fillsTo="orange" />
        <CallToActionHeader
          header={"Qualify Customers from Anywhere"}
          description={
            "Thousands of Canadian real estate agents and brokers have made the switch to Pinch. Pinch’s platform automates the verification of a borrower’s information to qualify them in minutes!"
          }
          button={
            <Button
              colour={`orange`}
              to="/#"
              onClick={() =>
                (window.location.href = `mailto:info@pinchfinancial.com`)
              }
            >
              {" "}
              Request a Demo{" "}
            </Button>
          }
          picture={
            <img src={LaptopDesktop} className={"picture"} />
            // <img srcset={`${LaptopMobile} 308w
            //               ${LaptopTablet} 512w
            //               ${LaptopDesktop} 1137w`}
            //   sizes={`(max-width: 768px) 308px,
            //           (max-width: 1336px) 512px,
            //           1137px`}
            //   src={LaptopDesktop}
            //   alt={"Pinch application on a laptop"}
            //   className={"picture"}
            // />
          }
          content={
            <img alt={"News Outlet Logos"} src={Logos} className={"news"} />
          }
        />
        <div className={"realtor-section"}>
          <BasicCardSection
            title={"Supporting Real Estate Agents"}
            header={"Have your Clients ready before the Open House"}
            content={
              "With Pinch’s platform you can convert your online traffic or customers into qualified buyers in minutes. You can also leverage our platform to capture greater revenue from your listings."
            }
            picture={
              <img
                srcset={`${RealEstateMobile} 308w 
                            ${RealEstateTablet} 512w
                            ${RealEstateDesktop} 1137w`}
                sizes={`(max-width: 768px) 308px,
                        (max-width: 1336px) 512px,
                        1137px`}
                src={RealEstateDesktop}
                alt={"Pinch application on a laptop"}
                className={"picture"}
              />
            }
          />
        </div>
        <div className={"broker-section"}>
          <BasicCardSection
            title={"Supporting Mortgage Brokers"}
            header={"Spend Your Time and Energy Wisely"}
            content={
              "Stop taking unqualified leads from your referral sources. With Pinch, your leads will arrive verified and qualified so you can focus on closing."
            }
            picture={
              <img
                srcset={`${BrokerMobile} 308w 
                            ${BrokerTablet} 512w
                            ${BrokerDesktop} 1137w`}
                sizes={`(max-width: 768px) 308px,
                        (max-width: 1336px) 512px,
                        1137px`}
                src={BrokerDesktop}
                alt={"Pinch application on a laptop"}
                className={"picture"}
              />
            }
          />
        </div>
        <div className={"lender-section"}>
          <BasicCardSection
            title={"Directly Integrated with Lenders"}
            header={"Bank & Lender Choices"}
            content={
              "Pinch works with a number of major banks and lenders to provide your customers with options. We’ll work with you to determine which lenders are right for your customers."
            }
            picture={
              <img
                srcset={`${LendersMobile} 308w 
                            ${LendersTablet} 512w
                            ${LendersDesktop} 1137w`}
                sizes={`(max-width: 768px) 308px,
                        (max-width: 1336px) 512px,
                         1137px`}
                src={LendersDesktop}
                alt={"Pinch application on a laptop"}
                className={"picture"}
              />
            }
          />
          <CallToActionFooter color={"orange"} />
        </div>
        <div className={"white-labels-section"}>
          <BasicCardSection
            header={"Our White Labels"}
            content={
              "Pinch provides its technology as a white label to referral sources to improve their customer experiences while also leveraging their origination opportunities."
            }
          />
          <div className={"picture-section"}>
            <img src={WhiteLabel1} className={"image"} />
            <img src={WhiteLabel2} className={"image"} />
            <img src={WhiteLabel3} className={"image"} />
            <img src={WhiteLabel4} className={"image"} />
          </div>
        </div>
        <Footer />
      </div>
      <Helmet>
        <title>Pinch Financial</title>
        <meta
          name="description"
          content="We've redefined the mortgage application to make it simple, quick, and secure so you can focus on finding and loving the right home. Our application takes less than 10 minutes and qualifies you with multiple lenders."
        />
      </Helmet>
    </>
  );
};

export default Homepage;
