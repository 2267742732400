import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationLink.css';

const NavigationLink = ({
  activeLink = false,
  className = '',
  to = "/",
  children,
  ...otherProperties
}) => {
  return (
    <Link
      {...otherProperties}
      to={to}
      className={`navigation-link ${className}`}
    >
      {children}
    </Link>
  )
}

export default NavigationLink;
