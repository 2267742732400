import React from "react";
import "./Footer.scss";
import LogoName from "~/assets/images/pinch-logo-white117x54.png";
import LogoSymbol from "~/assets/images/pinch-logo-footer.svg";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrivacyPolicy from "~/assets/files/Privacy_Policy.pdf";
import AccessAgreement from "~/assets/files/Access_Agreement.pdf";
import { Link } from "react-router-dom";
import WhiteLogo from "~/assets/images/pinch-logo-white.svg";

const Footer = ({ showRealtorInfo = true }) => {
  return (
    <div className="footer">
      <div className="section-column">
        <div className="image">
          <img src={WhiteLogo} />
        </div>
        <div className="content">
          <div className="address">
            <div>Pinch Financial</div>
            <div>2005 Sheppard Avenue East</div>
            <div>Toronto, ON, M2J 5B4</div>
          </div>
          <div className="contact">
            <div>
              <a href="mailto:info@pinchfinancial.com">
                info@pinchfinancial.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="links">
          <Link to="/home">Home</Link>
          <Link to="/realtor">Real Estate</Link>
          <Link to="/broker">Mortgage Brokers</Link>
        </div>
        <div className="links">
          <Link to="/learn">About Us</Link>
          <Link to="/integration">Platform</Link>
        </div>
        <div className="documents">
          <a href={PrivacyPolicy}>Privacy Policy</a>
          <a href={AccessAgreement}>Terms of Service</a>
          <a>&nbsp;</a>
        </div>
      </div>
      <div className="section">
        <div className="media">
          <div>@PinchFinancial</div>
          <div className="icons">
            <a href="https://www.instagram.com/pinchfinancial/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://twitter.com/PinchFinancial" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.facebook.com/PinchFinancial/" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.facebook.com/PinchFinancial/" target="_blank">
              <FontAwesomeIcon icon={faFacebookMessenger} />
            </a>
          </div>
          <a>&nbsp;</a>
        </div>
      </div>
      <div className="section logo">
        <div className="image">
          <img src={LogoSymbol} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
