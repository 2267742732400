import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Homepage from "~/components/Homepage";
import AboutUsPage from "~/components/AboutUsPage";
import IntegrationPage from "~/components/IntegrationPage";
import NotFoundPage from "~/components/NotFoundPage";
import RealtorPage from "~/components/RealtorPage";
import BrokerPage from "~/components/BrokerPage";

const PageRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      // Add more page routes here...
      <Route exact path={"/"}>
        <Homepage />
      </Route>
      <Route exact path="/home">
        <Homepage />
      </Route>
      <Route exact path="/integration">
        <IntegrationPage />
      </Route>
      <Route path="/learn">
        <AboutUsPage />
      </Route>
      <Route
        path="/app"
        render={() => {
          window.location.href = "https://apply.pinchfinancial.com/";
        }}
      />
      <Route exact path={"/realtor"}>
        <RealtorPage />
      </Route>
      <Route exact path={"/broker"}>
        <BrokerPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default PageRoutes;
