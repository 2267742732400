import React from 'react';
import { Helmet } from 'react-helmet';

import './IntegrationPage.scss';

// Components
import NavigationBar from '~/components/NavigationBar';
import BasicCardSection from "~/components/BasicCardSection";
import ThreeColumnSection from '~/components/ThreeColumnSection';
import CallToActionHeader from "~/components/CallToActionHeader";
import Button from '~/components/Button';
import Footer from '~/components/Footer';

// Images
import CTAMobile from '~/assets/images/code-block-334w.svg';
import CTATablet from '~/assets/images/code-block-475w.svg';
import CTADesktop from '~/assets/images/code-block-736w.svg';
import Icon1 from '~/assets/images/icons/1-08.svg';
import Icon2 from '~/assets/images/icons/1-09.svg';
import Icon3 from '~/assets/images/icons/1-10.svg';
import Icon4 from '~/assets/images/icons/1-11.svg';
import Icon5 from '~/assets/images/icons/1-14.svg';
import Icon6 from '~/assets/images/icons/1-15.svg';
import CaseStudy1 from '~/assets/images/case_study_1/CaseStudies_1.svg';
import CaseStudy2 from '~/assets/images/case_study_2/CaseStudies_2.svg';
import CaseStudy2Tablet from '~/assets/images/case_study_2/TABLET_CaseStudies2.svg';
import CaseStudy2Mobile from '~/assets/images/case_study_2/MOBILE_CaseStudies_2.svg';
import PortalView from '~/assets/images/portal-view.svg';
import SingleApplicant from '~/assets/images/portal-single-app.png';
import VerificationStamp from '~/assets/images/icons-verification-stamp.png';
import LendersMobile from "/assets/images/lenders-square.svg";
import LendersDesktop from "/assets/images/lenders.svg";

const containers = [
  {
    "icon": Icon1,
    "header": "Your Brand, Our Application",
    "content": "Your customers trust your brand and identity. Pinch’s white label engine will ensure that your clients can expect the same quality experience they’ve come to trust from our mortgage application too."
  },
  {
    "icon": Icon2,
    "header": "Verified Data",
    "content": "Banking and credit information are obtained directly from source, putting an end to fumbling through statements. For less digitally focused channels, we offer a suite of tools to speed up document verification."
  },
  {
    "icon": Icon3,
    "header": "Ultimate Lender Choice",
    "content": "There is no need to compromise on lender choice with Pinch. Whether through our own direct lender connections, or through multiple broker submission engines, Pinch can handle complex data transformations needed to submit to any lender."
  },
  {
    "icon": Icon4,
    "header": "Managed Compliance",
    "content": "Compliance is no longer a chore, with Pinch handling documents from applicants, mortgage brokers, and real estate agents alike. Let Pinch handle the collection of consent, income verification and more, to focus on a great customer experience."
  },
  {
    "icon": Icon5,
    "header": "Built with Trust",
    "content": "Whether it’s how we build or secure our services, we welcome your IT teams’ questions. We are proud of what we have built and happy to answer your questions."
  },
  {
    "icon": Icon6,
    "header": "Compatibility",
    "content": "Pinch has built integrations with CRMs, lending portals, broker portals and more. We have seen challenging XML, through to beautiful RESTful JSON. No matter your stack, we can talk integration."
  }
];

const IntegrationPage = () => {
  return (
    <>
      <div className="integration-component">
        <NavigationBar fillsTo='navy' />
        <CallToActionHeader
          title={"Comprehensive Platform"}
          header={'A Mortgage System For Every Need'}
          color={'navy'}
          button={<Button colour={`navy`} to="/#" onClick={() => window.location.href = `mailto:info@pinchfinancial.com`}> Request a Demo </Button>}
          picture={
            <img srcset={`${CTAMobile} 334w 
                          ${CTATablet} 475wW
                          ${CTADesktop} 736w`}
              sizes={`(max-width: 768px) 334px,
                      (max-width: 1336px) 475px,
                      736px`}
              src={CTADesktop}
              alt={"Code block with an object about Pinch"}
              className={"picture"}
            />
          }          content={
            <div className={'content-section'}>
              <div className={'header'}>{"From Origination to Funding"}</div>
              <div className={'content'}>
                <div className={'left content'}>
                  {"Pinch is a technology provider making mortgage referrals simple, digital, and secure. Pinch’s platform verifies all of an applicant’s information at the time of application to provide a full qualification in under 10 minutes."}
                </div>
                <div className={'left content'}>
                  {"The Pinch platform is designed to work with real estate agents, mortgage brokers, and lenders in a seamless way. With internal data tools, as support for a variety of front-end applications, Pinch will provide its technical knowhow to facilitate integration with your organization."}
                </div>
              </div>
            </div>
          }
        />
        <ThreeColumnSection
          title={"What makes Pinch Unique? "}
          header={"A Platform Built for Today’s Customers"}
          containers={containers}
        />
        <div className="case-studies-section">
          <BasicCardSection
            title={"Who uses Pinch"}
            header={"Case Studies"}
          />
          <div className="top">
            <div className="picture">
              <img src={CaseStudy1} />
            </div>
            <div className="text">
              {"One of Canada’s top real estate brokerages contacted Pinch early in the pandemic looking for ways to engage their customers with a virtual mortgage experience. After rapidly working with their creative and design team, and bringing an accomplished mortgage brokerage to the table, Pinch was able to provide them with an entirely digital mortgage experience in weeks. Pinch also transformed how the agents of that brokerage track their client referrals and assist in getting mortgages approved in a timely manner."}
            </div>
          </div>
          <div className="bottom">
            <div className="text">
              {"In 2019 Pinch was approached by a large Canadian bank in the hopes of providing their customers with a digital mortgage experience. Today, that bank is using Pinch to qualify its customers for mortgages completely online through new referral channels. Pinch’s application submission API made for seamless integration with the bank’s existing technology stack."}
            </div>
            <div className="picture">
              <img
                src={CaseStudy2}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Helmet>
        <title>Pinch Financial</title>
        <meta
          name="description"
          content="We've redefined the mortgage application to make it simple, quick, and secure so you can focus on finding and loving the right home. Our application takes less than 10 minutes and qualifies you with multiple lenders."
        />
      </Helmet>
    </>
  )
}

export default IntegrationPage;
