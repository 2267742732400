import React, {useEffect, useState, useRef, useLayoutEffect } from 'react';
import './NavigationBar.scss';
import PinchLogo from '~/components/PinchLogo';
import NavigationLink from '~/components/NavigationLink';
import Modal from '~/components/Modal';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "~/components/Button";
import {useHistory} from "react-router";

const NavigationBar = ({
  fillsTo = null,
  fillThreshold = 50,
  ...otherProperties
}) => {

  const [scrollFilled, setScrollFilled] = useState(fillThreshold === 0 || false);
  const [width, setWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  function handleScroll() {
    setScrollFilled(window.scrollY >= fillThreshold && fillsTo);
  }

  useEffect(() => {
    window.addEventListener('scroll',  handleScroll);
    handleScroll();
    return () => { window.removeEventListener('scroll', handleScroll)};
  }, [])

  function getNormalNavbar() {
    return (
      <div className={`navigation-bar ${scrollFilled ? fillsTo : 'not-scrolled'}`}>
        <NavigationLink className="logo" to="/">
          <PinchLogo fillColour={scrollFilled ? fillsTo : 'white'}/>
        </NavigationLink>
        <div className={'page-links'}>
          <NavigationLink to="/realtor">Real Estate</NavigationLink>
          <NavigationLink to="/broker">Mortgage Brokers</NavigationLink>
          <NavigationLink to="/learn">About Us</NavigationLink>
          <NavigationLink to="/integration">Platform</NavigationLink>
          {/* <NavigationLink to="/mortgages">Mortgages</NavigationLink> */}
          {/* <NavigationLink to="/insurance">Insurance</NavigationLink> */}
          {/* <NavigationLink to="/learn">Learn</NavigationLink> */}
          {/* <NavigationLink to="/business">Business</NavigationLink> */}
          {/* <NavigationLink to="/blog">Blog</NavigationLink> */}
        </div>
        <Button
          colour={`white square btn small ${fillsTo}`}
          onClick={() => {window.location.href = "http://underwriter.pinchfinancial.com/"}}
        >
          Sign In
        </Button>
      </div>
    )
  }

  function getSmallNavbar() {
    return (
      <div className={`navigation-bar small ${fillsTo}`}>
        <NavigationLink className="logo" to="/">
        <PinchLogo className={"small"} fillColour={fillsTo} />
        </NavigationLink>
        <div className='spacer'/>
        <FontAwesomeIcon icon={faBars} className={`menu-icon ${fillsTo}`} onClick={() => {setModal(true)}}/>
        {
          modal
            ?
            <Modal className='nav-modal' onCloseRequest={() => {setModal(false)}}>
              <div className='link-list'>
                <NavigationLink onClick={() => { setModal(false) }} to="/realtor">Real Estate</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/broker">Mortgage Brokers</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/learn">About Us</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/integration">Platform</NavigationLink>
                {/* <NavigationLink onClick={() => { setModal(false) }} to="/mortgages">Mortgages</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/insurance">Insurance</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/learn">Learn</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/business">Business</NavigationLink>
                <NavigationLink onClick={() => { setModal(false) }} to="/blog">Blog</NavigationLink> */}
              </div>
            </Modal>
            : null
        }
      </div>
    )
  }

  return (  width > 768
    ? getNormalNavbar()
    : getSmallNavbar()
  )
}

export default NavigationBar;
