import React from 'react';
import './InTheNewsSection.scss';
import House from '~/assets/images/news-house.png';
import Andrew from '~/assets/images/news-andrew.png';
import Neighborhood from '~/assets/images/news-neighborhood.png';
import Globe from '~/assets/images/news-globe.png';
import BNN from '~/assets/images/news-BNN.png';
import FinancialPost from '~/assets/images/news-FinancialPost.png';
import LinkButton from '~/components/LinkButton';

const InTheNewsSection = ({

}) => {

  return (
    <div className={"in-the-news-section"}>
      <div className={"header"}>{"In the News"}</div>
      <div className={"content"}>
        <div className={"section"}>
          <div className={"image"}>
            <img src={House} />
          </div>
          <div className={"name"}>
            <img src={Globe} />
          </div>
          <div className={"description"}>
            {"New real estate players try to gain foothold in Canada’s hot market"}
          </div>
          <div className={"link"}>
            <LinkButton
              colour='white'
              to='/#'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = "https://www.theglobeandmail.com/business/article-new-real-estate-players-try-to-gain-foothold-in-canadas-hot-market/"
              }}
            >
              Read the story
            </LinkButton>
          </div>
        </div>
        <div className={"section"}>
          <div className={"image"}>
            <img src={Andrew} />
          </div>
          <div className={"name"}>
            <img src={BNN} />
          </div>
          <div className={"description"}>
            {"Integrating online banking with mortgage application lessens potential risks"}
          </div>
          <div className={"link"}>
            <LinkButton
              colour='white'
              to='/#'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = "https://www.bnnbloomberg.ca/video/integrating-online-banking-with-mortgage-application-lessens-potential-risks-pinch-financial~1883383"
              }}
            >
              Read the story
            </LinkButton>
          </div>
        </div>
        <div className={"section"}>
          <div className={"image"}>
            <img src={Neighborhood} />
          </div>
          <div className={"name"}>
            <img src={FinancialPost} />
          </div>
          <div className={"description"}>
          {"Startups shift focus to survival as COVID-19 uncertainty looms"}
        </div>
          <div className={"link"}>
            <LinkButton
              colour='white'
              to='/#'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = "https://financialpost.com/technology/startups-shift-focus-to-survival-as-covid-19-uncertainty-looms"
              }}
            >
              Read the story
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );

};

export default InTheNewsSection;
