import React from 'react';
import { Helmet } from 'react-helmet';
import './RealtorPage.scss';

// Components
import NavigationBar from '~/components/NavigationBar';
import Footer from '~/components/Footer';
import InTheNewsSection from "~/components/InTheNewsSection";
import CallToActionHeader from "~/components/CallToActionHeader";
import LinkButton from '~/components/LinkButton';
import Button from '~/components/Button';
import BasicCardSection from "~/components/BasicCardSection";
import CallToActionFooter from "~/components/CallToActionFooter";
import ThreeColumnSection from '~/components/ThreeColumnSection';

// Images
import CTATablet from '~/assets/images/realtor-page-512w.svg';
import CTADesktop from '~/assets/images/HOMEPAGE_RealEstatePreview.svg';
import Icon1 from '~/assets/images/icons/1-01.svg';
import Icon2 from '~/assets/images/icons/1-02.svg';
import Icon3 from '~/assets/images/icons/1-03.svg';
import AppUI from '~/assets/images/uw-ui-coins.svg';
import LendersDesktop from '~/assets/images/lenders.svg';
import LendersMobile from "~/assets/images/lenders-square.svg";
import IntegratedPartnersDesktop from "~/assets/images/RealEstate_WL_desktop.svg";
import IntegratedPartnersTablet from "~/assets/images/RealEstate_WL_tablet.svg";
import IntegratedPartnersMobile from "~/assets/images/RealEstate_WL_mobile.svg";

const containers = [
  {
    "icon": Icon1,
    "header": "Your Brand",
    "content": "Customers have chosen your brand and reputation, we want to keep it that way. We build our white labels for you using your existing brand guidelines. Customers applying on your website will believe you are providing them with this new mortgage experience."
  },
  {
    "icon": Icon2,
    "header": "Seamless Integration",
    "content": "After an initial consultation with your creative and technical teams, we can help you integrate calls-to-action to ensure your clients are ushered from listings to mortgage applications seamlessly."
  },
  {
    "icon": Icon3,
    "header": "Built with Agents for Agents",
    "content": "Your time is important to us. We made our product straightforward and focussed on delivering results. With our platform your team can easily manage all of their referrals in minutes."
  }
];

const RealtorPage = () => {
  return (
    <>
      <div className="realtor-component">
        <NavigationBar fillsTo='green' />
        <CallToActionHeader
          title={"Real Estate Agents"}
          header={"Help your clients through every step of their journey"}
          picture={
            <img srcset={`${CTATablet} 512w
                          ${CTADesktop} 1170w`}
              sizes={`(max-width: 1336px) 512px,
                      1170w`}
              src={CTADesktop}
              alt={"Pinch realtor application"}
              className={"picture"}
            />
          }
          color={"green"}
          button={<Button colour={`green`} to="/#" onClick={() => window.location.href = `mailto:info@pinchfinancial.com`}> Request a Demo </Button>}
          content={
            <div className={'content-section'}>
              <div className={'header'}>{"What is a White Label?"}</div>
              <div className={'content'}>
                {"We have built Canada’s first truly complete online mortgage platform for real estate agents, mortgage brokers, and lenders. We provide this technology free of charge as a white label to our referral partners."}
                <br />
                <br />
                {"A white label means that we provide our technology to you as an out of the box solution branded in your customer experience. Customers see your website and your brand, we take a back seat."}
                <br />
              </div>
            </div>
          }
        />
        <ThreeColumnSection
          title={'Improving Your Customer Experience'}
          header={'Switching to our platform provides a number of benefits'}
          containers={containers}
        />
        <div className={'uw-ui-section'}>
          <div className={'header'}>{"New Earning Opportunities"}</div>
          <div className={'text-content'}>
            {"To align incentives and outcomes, our business model is structured around compensation for origination. All referral sources are compensated  when a lender successfully funds a mortgage. With Pinch you can begin to immediately start earning revenue from your online listing views or existing customer relationships."}
          </div>
          <div className={'picture-content'}>
            <img src={AppUI} className={'picture'} />
          </div>
        </div>
        <div className={'lenders-section'}>
          <BasicCardSection
            title={"Designed for Real Estate Agents"}
            header={"Qualify Your Clients in Minutes"}
            content={"With our platform your agents can provide a cutting edge digital experience to their customers, while also qualifying their prospects with multiple lenders and improving their sales funnel."}
            picture={
              <picture>
                <source media="(max-width: 1336px)" srcset={`${LendersMobile}`} />
                <img srcset={`${LendersMobile} 773w
                          ${LendersDesktop} 1543w`}
                  sizes={`(max-width: 1336px) 773px,
                          1543px`}
                  src={LendersDesktop}
                  alt={"Choice of lender options"}
                  className={"picture"}
                />
              </picture>
            }
          />
          <CallToActionFooter color={'green'} />
        </div>
        <div className="sotheby-section">
          <BasicCardSection
            title={"Trusted Today"}
            header={"Join Canada’s Most Innovative Companies"}
            content={"Canada's top realty brands have already started to connect their customers to a better mortgage experience. With our white label service, your brand and identity can be integrated into our mortgage application to build the service your customers have come to expect. Agents and brokers in your company can leverage our powerful customer portals to generate custom referral links and track the status of their customers."}
            picture={
              <picture>
                <source srcSet={IntegratedPartnersMobile} media="(max-width: 768px)"/>
                <source srcSet={IntegratedPartnersTablet} media="(max-width: 1336px)"/>
                <source srcSet={IntegratedPartnersDesktop} media="(min-width: 1336px)"/>
                <img src={IntegratedPartnersDesktop}/>
              </picture>
            }
          />
        </div>
        <InTheNewsSection />
        <Footer />
      </div>
      <Helmet>
        <title>Pinch Financial</title>
        <meta
          name="description"
          content="We've redefined the mortgage application to make it simple, quick, and secure so you can focus on finding and loving the right home. Our application takes less than 10 minutes and qualifies you with multiple lenders."
        />
      </Helmet>
    </>
  )
}

export default RealtorPage;
