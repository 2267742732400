import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './default-theme.css';
import './default-styling.css';
import Root from '~/components/Root';
import './assets/fonts/CircularStandardMedium.ttf';
import './assets/fonts/CircularStandardBlack.ttf';

ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);
