import React from 'react';
import { Helmet } from 'react-helmet';

import './AboutUsPage.scss';

// Components
import NavigationBar from '~/components/NavigationBar';
import CallToActionHeader from "~/components/CallToActionHeader";
import BasicCardSection from "~/components/BasicCardSection";
import InTheNewsSection from "~/components/InTheNewsSection";
import Button from '~/components/Button';
import Footer from '~/components/Footer';

// Images
import CTAMobile from '~/assets/images/photo2-280w.png';
import CTATablet from '~/assets/images/photo2-455w.png';
import CTADesktop from '~/assets/images/photo2-743w.png';
import Rocket from '~/assets/images/rocket_NEW.png';

const AboutUsPage = () => {

  return (
    <>
      <div className='about-us-component'>
        <NavigationBar fillsTo='blue' />
        <CallToActionHeader
          title={"About Us"}
          header={"Why We’re Passionate About Digital Mortgages"}
          color={'blue'}
          picture={
            <img srcset={`${CTAMobile} 280w 
                          ${CTATablet} 455w
                          ${CTADesktop} 743w`}
              sizes={`(max-width: 768px) 280px,
                      (max-width: 1336px) 455px,
                      743px`}
              src={CTADesktop}
              alt={"Founders working together"}
              className={"picture"}
            />
          }          
          content={
            <div className={'content-section'}>
              <div className={'header'}>{"Our Story"}</div>
              <div className={'content'}>
                <div className={'left content'}>
                  {"Our mission is to make getting a mortgage simple, fast, and secure."}
                  <br />
                  <br />
                  {"That’s why we built Pinch, a mortgage platform that provides full mortgage qualifications in under 10 minutes!"}
                  <br />
                </div>
                <div className={'right content'}>
                  {"We provide our technology as a white label solution for real estate brokerages, mortgage brokerages and major lenders to help improve their customer experiences and sales."}
                  <br />
                  <br />
                  {"Based in Toronto, we’re proud to be a Canadian company and we hope you will join us in helping homeowners switch to digital mortgages."}
                  <br />
                  <br />
                </div>
              </div>
            </div>
          }
        />
        <div className="team-section">
          <BasicCardSection
            title={"A Shared Mission"}
            header={"Our Team"}
            picture={ <img src={Rocket} className={"wave"} />}
            content={
              "Pinch has a team of hard working professionals passionate about bringing change to the Canadian mortgage industry. From former bankers who know their clients deserve better, to engineers that take passion in building innovative solutions. Together we have created a mortgage platform that brings a completely new experience for homebuyers.\n\n"
              + "Our mission is to tackle the challenges our customers are facing, from real estate agents looking for digital solutions, to mortgage brokers seeking a new way to approach digital acquisition."
            }
          />
        </div>
        <InTheNewsSection />
        <div className="contact-section">
          <div className='header'>
            Interested in Learning More?
          </div>
          <Button
            colour="white"
            onClick={() => window.location.href = `mailto:info@pinchfinancial.com`}
          >
            Get In Touch
          </Button>
        </div>
        <Footer />
      </div>
      <Helmet>
        <title>About Pinch Financial</title>
        <meta
          name="description"
          content="Our mission is to make the journey to homeownership simple, fast, and secure."
        />
      </Helmet>
    </>
  )

}

export default AboutUsPage;
