import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PageRoutes from '~/routing';
import withTracking from '~/components/WithTracking';
import './Root.css';

const Root = () => {
  return (
    <BrowserRouter>
      <Route component={withTracking(PageRoutes)}/>
    </BrowserRouter>
  )
};

export default Root;
