import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';

const Modal = ({
  className = '',
  modalParent = document.body,
  children = <div/>,
  onCloseRequest = () => {},
  closeOnBackdropClick = true,
  closeOnOutsideClick = true,
  ...otherProperties
}) => {
  const
    contentRef = useRef(null),
    modal = document.createElement('div'),
    modalContents = React.cloneElement(children, { requestModalClose: onCloseRequest })
  ;

  if (contentRef.current === null)
    contentRef.current = modal;

  function cleanupModal() {
    onCloseRequest();
    modalParent.removeChild(modal);
    document.removeEventListener('mousedown', handleClickOutside);
  }

  function handleClickOutside(clickEvent) {
    if (closeOnBackdropClick && clickEvent.target === modal) {
      cleanupModal();
    }
    else if (closeOnOutsideClick && !modal.contains(clickEvent.target)) {
      cleanupModal();
    }
  }

  useEffect( () => {
    modal.className = `modal-backdrop ${className}`;
    modalParent.appendChild(modal);
    document.addEventListener('mousedown', handleClickOutside);
    return cleanupModal;
  }, [contentRef])

  return ReactDOM.createPortal(
    modalContents,
    contentRef.current
  );
}

export default Modal;
