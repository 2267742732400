import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

ReactPixel.init("1434146266642616", undefined, {
  autoConfig: true,
  debug: true,
});
const withTracking = (WrappedComponent, options = {}) => {
  const HOC = (props) => {
    useEffect(() => {
      window.gtag("event", "page_view", {
        page: props.location.pathname + props.location.search,
        page_search: props.location.search,
      });
      ReactPixel.pageView();
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracking;
