import React from 'react';
import './ThreeColumnSection.scss';
import DefaultIcon from '~/assets/images/default-icon.svg';

const ThreeColumnSection = ({
  title = '',
  header = '',
  containers = []
}) => {
  
  const content = containers.map(({icon, header, content}) => {
    return (
      <div className="container">
        <div className={'icon-content'}>
          <img src={icon || DefaultIcon} className={'icon'} />
        </div>
        <div className={'text-content'}>
          <div className={'header'}>
            {header || ''}
          </div>
          <div className={'content'}>
            {content || ''}
            </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="three-column-section-component">
        <div className="title">{title}</div>
        <div className="header">{header}</div>
        <div className="content">
        {content}
        </div>
      </div>
    </>
  )
}

export default ThreeColumnSection;
